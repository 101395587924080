import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NewHeader from "./Components/newHeader";
import { useTranslation } from "react-i18next";

const facultyMembers = [
  {
    name: "Mr. Hacene Mahmoudi",
    role: "Director of the School",
    email: "ensnn2023@gmail.com",
  }
];

export default function Faculty() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      once: false,
      duration: 1200, // Adjust animation duration as needed
    });
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-default text-gray-950">
      <NewHeader isField={false} />
      <section className="container mx-auto px-4 py-4 pt-32">
        <div className="text-center mb-4">
          <h1 className="text-4xl font-[Lexend] text-center font-black leading-tighter tracking-tighter mb-4 text-primary">
            {t("Faculty Members")}
          </h1>
          <p className="text-xl text-gray-600">
            {t(
              "Explore Our Faculty Members of National School of Nanoscience and Nanotechnology"
            )}
          </p>
        </div>
        <div className="flex flex-wrap justify-center items-center">
          {facultyMembers.map((member, index) => (
            <div
              key={index}
              data-aos="fade-up"
              className="flex flex-col items-center text-center bg-white p-4 rounded-md shadow-lg"
            >
              <h2 className="text-2xl font-bold text-secondary mb-2">
                {t(member.name)}
              </h2>
              <h3 className="text-xl text-gray-600 mb-2">{t(member.role)}</h3>
              <p className="text-lg text-gray-600 mb-4">{member.email}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
