import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export const options = {
  responsive: true,

  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["1st year, Section A", "1st year, Section B"];

export const data2 = {
  labels,
  datasets: [
    {
      label: "Students",
      data: [10, 20],
      backgroundColor: "rgba(255, 100, 132, 0.2)",
      stack: "Persons 0",
    },
  ],
};

export default function ChartsRow({ students, teachers }) {
  const data = {
    labels: ["Students", "Teachers"],
    datasets: [
      {
        label: "Persons: ",
        data: [students, teachers],
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="flex items-center justify-evenly">
      <div className="p-2 bg-default rounded-xl m-3">
        <Pie data={data} />
        <h1 className="text-center font-semibold text-lg m-2">
          Total Students and Teachers
        </h1>
      </div>

      {/*<div className="w-full flex-[0.5] p-2 bg-[#1d2085ab] rounded-xl m-3">
        <Bar options={options} data={data2} className="min-h-[20vh]" />
        <h1 className="text-center font-semibold text-lg m-2">
          Total Students by Gender
        </h1>
  </div>*/}
    </div>
  );
}
