import React from "react";
import NewHeader from "./Components/newHeader";
import { useTranslation } from "react-i18next";

export default function Conditions() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col min-h-screen">
      <NewHeader />
      <div className="container py-16 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="text-center pb-12">
            <h2 className="text-3xl font-semibold text-gray-900">
              {t("Internal Law")}
            </h2>
          </div>
          <p className="text-center text-lg">{t("Internal Law")}</p>
        </div>
      </div>
    </div>
  );
}
