import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import "leaflet-defaulticon-compatibility";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export default function Footer() {
  // Language state and function
  const { t } = useTranslation();

  return (
    <footer dir="ltr" className="bg-[#05212F] text-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="flex flex-col md:flex-row justify-between items-start py-10">
          {/* Contact block */}
          <div className="w-full md:w-1/3 mb-8 md:mb-0">
            <h3 className="font-bold text-lg sm:text-xl leading-tight mb-4">
              {t("Contact us")}
            </h3>
            <div className="text-sm text-gray-300 space-y-2">
              <p>{t("National School of Nanoscience and Nanotechnology")}</p>
              <p>{t("Algiers, Sidi Abdellah, Mahelma")}</p>
              <p>
                {t("Phone Number")}:{" "}
                <a
                dir="ltr"
                  href="tel:+023083670"
                  className="text-tertiary hover:text-tertiary/60"
                >
                  023 08 36 70
                </a>
              </p>
              <p>
                {t("Email Address")}:{" "}
                <a
                  href="mailto:ensnn2023@gmail.com"
                  className="text-tertiary hover:text-tertiary/60"
                >
                  ensnn2023@gmail.com
                </a>
              </p>
            </div>
          </div>

          {/* Helpful links block */}
          <div className="w-full md:w-1/5 mb-8 md:mb-0">
            <h3 className="font-bold text-lg sm:text-xl leading-tight mb-4">
              {t("Helpful links")}
            </h3>
            <ul className="text-sm text-gray-300 space-y-2">
              <li>
                <Link
                  className="text-tertiary hover:text-tertiary/60"
                  to="/program"
                >
                  {t("Curriculum")}
                </Link>
              </li>
              <li>
                <a
                  target="_blank"
                  className="text-tertiary hover:text-tertiary/60"
                  href="https://www.mesrs.dz/index.php/fr/plateformes-mesrs/"
                >
                  {t("MESRS Platforms")}
                </a>
              </li>
            </ul>
          </div>

          {/* Policies block */}
          <div className="w-full md:w-1/5 mb-8 md:mb-0">
            <h3 className="font-bold text-lg sm:text-xl leading-tight mb-4">
              {t("Policies")}
            </h3>
            <ul className="text-sm text-gray-300 space-y-2">
              <li>
                <Link
                  className="text-tertiary hover:text-tertiary/60"
                  to="/conditions"
                >
                  {t("Internal Law")}
                </Link>
              </li>
            </ul>
          </div>

          {/* Map block */}
          <div className="w-full md:w-1/3">
            <h3 className="font-bold text-lg sm:text-xl leading-tight mb-4">
              {t("Find us here")}
            </h3>
            <div className="overflow-hidden rounded-lg shadow-lg">
              <MapContainer
                preferCanvas={true}
                center={[36.689075, 2.869052]}
                zoom={15}
                scrollWheelZoom={true}
                style={{ height: "20rem", width: "100%" }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[36.689075, 2.869052]}>
                  <Popup>
                    {t("National School of Nanoscience and Nanotechnology")}
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
        </div>

        {/* Bottom area */}
        <div className="border-t border-gray-100 py-4 md:py-8 flex flex-col md:flex-row items-center">
          {/* Social media icons */}
          <div className="flex space-x-4 z-10 left-0 mb-4 md:mb-0">
            <a href="#" className="text-tertiary hover:text-tertiary/60">
              <FaFacebook size={24} />
            </a>
            {/*<a href="#" className="text-tertiary hover:text-tertiary/60">
              <FaInstagram size={24} />
            </a>*/}
            <a href="#" className="text-tertiary hover:text-tertiary/60">
              <FaYoutube size={24} />
            </a>
            <a href="#" className="text-tertiary hover:text-tertiary/60">
              <FaLinkedin size={24} />
            </a>
          </div>

          {/* Name */}
          <div className="relative md:absolute text-sm text-gray-300 text-center w-full max-w-[72rem] break-words px-4">
            {new Date().getFullYear()}{" "}
            {t("National School of Nanoscience and Nanotechnology")}
          </div>
        </div>
      </div>
    </footer>
  );
}
