import React from "react";
import NewHeader from "./Components/newHeader";
import { useTranslation } from "react-i18next";

export default function Platforms() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col min-h-screen">
      <NewHeader isPlatforms />
      <div className="container text-gray-900 py-4 pt-32 mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-[Lexend] text-center font-black leading-tighter tracking-tighter text-primary mb-8">
          {t("MESRS Platforms")}
        </h1>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">{t("ALUMNI Platform")}</h2>
          <p className="mb-6">
            {t(
              "The term “ALUMNI” refers to former students of an educational institution. More broadly, the term is used to refer to associations of former students. Within these associations, former students from the same school gather especially to continue strengthening the bonds of brotherhood that were woven during their studies."
            )}
          </p>
          <a
            href="https://www.mesrs.dz/index.php/fr/plateformes-mesrs/alumni/"
            target="_blank"
            rel="noopener noreferrer"
            className="py-4 px-2 bg-tertiary hover:bg-tertiary/70 rounded-md inline-block text-white"
          >
            {t("Access ALUMNI Platform")}
          </a>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">
            {t("PROGRES Schooling Platform")}
          </h2>
          <p className="mb-6">{t("Students schooling management.")}</p>
          <a
            href="https://www.mesrs.dz/index.php/fr/progres/"
            target="_blank"
            rel="noopener noreferrer"
            className="py-4 px-2 bg-tertiary hover:bg-tertiary/70 rounded-md inline-block text-white"
          >
            {t("Access PROGRES Platform")}
          </a>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">{t("Ask me")}</h2>
          <p className="mb-6">
            {t(
              "Ask Me is a question and answer website. You can ask questions in various fields: scientific, religious, technological, entertainment, literary, puzzles, and educational programs. Submit your questions now and join us."
            )}
          </p>
          <a
            href="https://ask.mesrs.dz/"
            target="_blank"
            rel="noopener noreferrer"
            className="py-4 px-2 bg-tertiary hover:bg-tertiary/70 rounded-md inline-block text-white"
          >
            {t("Access Ask me Platform")}
          </a>
        </section>
      </div>
    </div>
  );
}
