import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { FaSearch, FaBell } from "react-icons/fa";
import { motion } from "framer-motion";
import dayjs from "dayjs";

export default function TeacherDashboard() {
  const [classesToday, setClassesToday] = useState([]);

  return (
    <div className="flex min-h-screen grow w-full">
      <Sidebar isHome />

      <div className="h-full w-full flex flex-col pt-16 pl-2 pr-5">
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <div className="text-2xl font-semibold">Welcome, Teacher</div>
            <div className="text-xl text-gray-600">
              Today is {dayjs().format("dddd, MMMM D, YYYY")}
            </div>
          </div>
          <div className="flex space-x-2 mr-10">
            <FaSearch size={17} className="text-gray-500" />
            <FaBell size={17} className="text-gray-500" />
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="cursor-pointer rounded-full bg-gray-200 p-2"
            >
              {/* Replace with actual profile picture */}
              <div className="w-8 h-8 bg-gray-400 rounded-full"></div>
            </motion.div>
          </div>
        </div>

        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          Nothing to show
        </div>

        {/* Placeholder for news and updates section */}
        <div className="mt-8 bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">
            News and Updates
          </h2>
          {/* Implement news feed or announcements here */}
          <div className="text-gray-600">
            {/* Placeholder content */}
            No news or updates available at this time.
          </div>
        </div>
      </div>
    </div>
  );
}
