import React, { createContext, useState, useEffect } from "react";
import i18n from "./i18n";

export const DirectionContext = createContext();

export const DirectionProvider = ({ children }) => {
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setDirection(lng === "ar" ? "rtl" : "ltr");
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <DirectionContext.Provider value={direction}>
      {children}
    </DirectionContext.Provider>
  );
};
