import { useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "en", label: "English" },
  { code: "ar", label: "Arabic" },
  { code: "fr", label: "French" },
];

export default function NewMobileMenu() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const trigger = useRef(null);
  const mobileNav = useRef(null);

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
    i18n.changeLanguage(newLanguage.code);
    setShowDropdown(false);
  };

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <div className="flex md:hidden w-full">
      <div className="flex justify-between items-center w-full px-4 py-4">
        <Link to="/" className="text-2xl font-bold">
           <img alt="Logo" src= {top ? "/full_logo3.png" : "/full_logo2.png"} className="max-h-24 w-auto" />
        </Link>
        {/* Hamburger button */}
        <div className="flex items-center">
          <div
            className="relative"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <button className="flex items-center p-3 rounded-md">
              <HiDotsVertical />
            </button>
            {showDropdown && (
              <ul className="absolute right-0 top-full mt-2 bg-white rounded-md shadow-lg">
                {languages.map((lang) => (
                  <li key={lang.code}>
                    <button
                      onClick={() => handleLanguageChange(lang)}
                      className="w-full text-gray-900 block px-5 py-2 hover:bg-gray-200 text-lg rounded-md"
                    >
                      {lang.label}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <button
            ref={trigger}
            className={`hamburger ml-2 ${mobileNavOpen && "active"}`}
            aria-controls="mobile-nav"
            aria-expanded={mobileNavOpen}
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          >
            <span className="sr-only">Menu</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="4" width="24" height="2" />
              <rect y="11" width="24" height="2" />
              <rect y="18" width="24" height="2" />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile navigation */}
      <div ref={mobileNav}>
        <Transition
          show={mobileNavOpen}
          as="nav"
          id="mobile-nav"
          className="absolute top-full z-20 left-0 w-full overflow-scroll bg-white"
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ul className="px-5 py-2">
            <li>
              <Link
                to="/field"
                className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                onClick={() => setMobileNavOpen(false)}
              >
                {t("Our Field")}
              </Link>
              <Link
                to="/program"
                className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                onClick={() => setMobileNavOpen(false)}
              >
                {t("Program")}
              </Link>
              <Link
                to="/platforms"
                className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                onClick={() => setMobileNavOpen(false)}
              >
                {t("Ministry's Platforms")}
              </Link>
              <Link
                to="/about"
                className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                onClick={() => setMobileNavOpen(false)}
              >
                {t("About")}
              </Link>
              <Link
                to="/contact"
                className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                onClick={() => setMobileNavOpen(false)}
              >
                {t("Contact")}
              </Link>
              {/*<Link
                to="/virtualtours"
                className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                onClick={() => setMobileNavOpen(false)}
              >
                {t("Virtual Tours")}
              </Link>*/}
              <Link
                to="/login"
                className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                onClick={() => setMobileNavOpen(false)}
              >
                {t("Platform")}
              </Link>
            </li>
          </ul>
        </Transition>
      </div>
    </div>
  );
}
