import React, { useState, useEffect } from "react";
import { baseURL } from "./constants";
import NewHeader from "./Components/newHeader";
import { useTranslation } from "react-i18next";

function ConsultationsListPage() {
  const [consultations, setConsultations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { t } = useTranslation(); 

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const response = await fetch(`${baseURL}/consultations`);
        if (!response.ok) {
          throw new Error("Failed to fetch consultations");
        }
        const data = await response.json();
        setConsultations(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConsultations();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col items-center">
        <NewHeader isConsultations />
        <h1 className="text-center">Loading...</h1>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        Error: {error}
      </div>
    );
  }

  const consultationsList = consultations.filter(
    (consultation) => consultation.type === "Consultation"
  );
  const provisionalAllocationsList = consultations.filter(
    (consultation) => consultation.type === "Provisional Allocation"
  );

  return (
    <div className="min-h-screen flex flex-col">
      <NewHeader isConsultations />
      <div className="container py-16 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="text-center pb-8">
            <h2 className="text-4xl font-[Lexend] text-center font-black leading-tighter tracking-tighter mb-4 text-primary">
              {t("Advertisement for Consultations")}
            </h2>
          </div>

          <div className="grid lg:grid-cols-2 gap-12">
            {/* Consultations */}
            <div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              {t("Consultations")}
              </h3>
              {consultationsList.length === 0 ? (
                <p className="py-5 text-center text-gray-900 font-medium text-xl">
                  {t("Nothing to show")}
                </p>
              ) : (
                <ul className="grid grid-cols-1 gap-6">
                  {consultationsList.map((consultation) => (
                    <a
                      href={baseURL + "/" + consultation.file_path}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={consultation.id}
                    >
                      <div className="p-6 rounded-md shadow-md transition-all duration-300 hover:shadow-xl block text-center text-gray-700 hover:text-gray-900">
                        <h3 className="text-lg font-semibold text-gray-900">
                          {consultation.title}
                        </h3>
                        <p className="text-gray-600 mt-2">
                          {consultation.type}
                        </p>
                      </div>
                    </a>
                  ))}
                </ul>
              )}
            </div>

            {/* Provisional Allocations */}
            <div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              {t("Provisional Allocations")}
              </h3>
              {provisionalAllocationsList.length === 0 ? (
                <p className="py-5 text-center text-gray-900 font-medium text-xl">
                  {t("Nothing to show")}
                </p>
              ) : (
                <ul className="grid grid-cols-1 gap-6">
                  {provisionalAllocationsList.map((consultation) => (
                    <li
                      key={consultation.id}
                      className="bg-white shadow-lg rounded-md overflow-hidden"
                    >
                      <a
                        href={baseURL + "/" + consultation.file_path}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-6 rounded-md shadow-md transition-all duration-300 hover:shadow-xl block text-center text-gray-700 hover:text-gray-900"
                      >
                        <h3 className="text-lg font-semibold text-gray-900">
                          {consultation.title}
                        </h3>
                        <p className="text-gray-600 mt-2">
                          {consultation.type}
                        </p>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultationsListPage;
