import React, { useState } from "react";
import NewHeader from "./Components/newHeader"; // Assuming you have this component
import { useTranslation } from "react-i18next";

const year = {
  id: 1,
  semesters: [
    {
      id: 1,
      name: "First Semester",
      subjects: [
        { name: "Real Analysis 1", coefficient: 6, hours: 90 },
        { name: "Algebra 1", coefficient: 3, hours: 45 },
        { name: "Probability and Statistics 1", coefficient: 3, hours: 45 },
        { name: "Physics 1", coefficient: 5, hours: 75 },
        { name: "Chemistry 1", coefficient: 5, hours: 75 },
        { name: "Computer Science 1", coefficient: 3, hours: 45 },
        { name: "Technical Drawing", coefficient: 1, hours: 45 },
        { name: "Human Engineering 1", coefficient: 1, hours: 15 },
        { name: "Economy 1", coefficient: 1, hours: 15 },
        { name: "French Language 1", coefficient: 1, hours: 15 },
        { name: "English Language 1", coefficient: 1, hours: 15 },
      ],
    },
    {
      id: 2,
      name: "Second Semester",
      subjects: [
        { name: "Real Analysis 2", coefficient: 5, hours: 90 },
        { name: "Algebra 2", coefficient: 4, hours: 45 },
        { name: "Probability and Statistics 2", coefficient: 3, hours: 45 },
        { name: "Physics 2", coefficient: 5, hours: 75 },
        { name: "Chemistry 2", coefficient: 5, hours: 75 },
        { name: "Computer Science 2", coefficient: 3, hours: 45 },
        { name: "Computer Aided Design", coefficient: 1, hours: 45 },
        { name: "Human Engineering 2", coefficient: 1, hours: 15 },
        { name: "Economy 2", coefficient: 1, hours: 15 },
        { name: "French Language 2", coefficient: 1, hours: 15 },
        { name: "English Language 2", coefficient: 1, hours: 15 },
      ],
    },
  ],
};

export default function ProgramOfStudy() {
  const { t } = useTranslation();
  const [activeSemester, setActiveSemester] = useState(1);

  return (
    <div className="flex flex-col min-h-screen">
      <NewHeader isProgram />
      <div className="container py-16 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="text-center pb-8">
            <h2 className="text-4xl font-[Lexend] text-center font-black leading-tighter tracking-tighter mb-4 text-primary">
              {t("Program of Study")}
            </h2>
            <p className="mt-4 text-gray-600">
              {t(
                "A comprehensive overview of the courses and curriculum for the Engineering Degree in nanoscience and nanotechnology."
              )}
            </p>
          </div>

          <div className="flex justify-center mb-8">
            {year.semesters.map((semester) => (
              <button
                key={semester.id}
                className={`
                  px-6 py-3 mx-2 
                  rounded-md
                  font-semibold
                  transition
                  duration-300
                  ease-in-out
                  ${
                    activeSemester === semester.id
                      ? "bg-primary text-white shadow-md"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }
                `}
                onClick={() => setActiveSemester(semester.id)}
              >
                {t(semester.name)}
              </button>
            ))}
          </div>

          <div dir="ltr" className="mt-8">
            {year.semesters.map((semester) => (
              <div key={semester.id} hidden={activeSemester !== semester.id}>
                <table className="w-full text-left text-sm border-2 border-gray-300 shadow-lg">
                  <thead>
                    <tr className="bg-default text-gray-700">
                      <th className="px-6 py-4 border-b border-gray-300">
                        {t("Module")}
                      </th>
                      <th className="px-6 py-4 border-b border-gray-300">
                        {t("Coefficient")}
                      </th>
                      <th className="px-6 py-4 border-b border-gray-300">
                        {t("Hours")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-800">
                    {semester.subjects.map((subject) => (
                      <tr key={subject.name} className="hover:bg-gray-50">
                        <td className="px-6 py-4 border-b border-gray-200">
                          {t(subject.name)}
                        </td>
                        <td className="px-6 py-4 border-b border-gray-200 text-right">
                          {subject.coefficient}
                        </td>
                        <td className="px-6 py-4 border-b border-gray-200 text-right">
                          {subject.hours}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
