import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { baseURL } from "./constants";
import NewHeader from "./Components/newHeader";

const NewsDetails = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);

  useEffect(() => {
    const fetchNewsItem = async () => {
      try {
        const response = await axios.get(`${baseURL}/news/${id}`);
        setNewsItem(response.data);
      } catch (error) {
        console.error("Error fetching news item:", error);
      }
    };

    fetchNewsItem();
  }, [id]);

  if (!newsItem) {
    return (
      <div className="flex flex-col min-h-screen">
        <NewHeader />
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <NewHeader />
      <div className="container mx-auto px-4 py-16">
        <div className="bg-white rounded-md shadow-lg overflow-hidden">
          {/* Cover Image */}
          {newsItem.hasImg && (
            <img
              src={`${baseURL}${newsItem.imagePath}`}
              alt={newsItem.title}
              className="w-full"
            />
          )}

          <div className="p-8">
            {/* Title */}
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              {newsItem.title}
            </h1>

            {/* Date */}
            <p className="text-gray-600 text-sm mb-6">
              {new Date(newsItem.date).toLocaleDateString("en-CA")}
            </p>

            {/* Content */}
            <p className="text-gray-800 leading-relaxed">{newsItem.content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;
