import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useStore } from "../store";
import axios from "axios";
import { baseURL } from "../constants";
import { motion } from "framer-motion";
import { FaChalkboardTeacher } from "react-icons/fa";

const containerVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, type: "spring", stiffness: 100 },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, type: "spring", stiffness: 100 },
  },
};

export default function StudentClasses() {
  const user = useStore((state) => state.user);
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStudentData = async () => {
      setIsLoading(true);
      try {
        const sectionResponse = await axios.get(
          `${baseURL}/student/${user.id}/sections`
        );
        const sections = sectionResponse.data;

        const groupResponse = await axios.get(
          `${baseURL}/student/${user.id}/groups`
        );
        const groups = groupResponse.data;

        const teacherList = [];

        // Fetch teachers for each section
        for (const section of sections) {
          const sectionTeachersResponse = await axios.get(
            `${baseURL}/section/${section.id}/teachers`
          );
          sectionTeachersResponse.data.forEach((teacher) =>
            teacherList.push({
              ...teacher,
              classType: "Section",
              className: section.name,
              classId: section.id,
            })
          );
        }

        // Fetch teachers for each group
        for (const group of groups) {
          const groupTeachersResponse = await axios.get(
            `${baseURL}/group/${group.id}/teachers`
          );
          groupTeachersResponse.data.forEach((teacher) =>
            teacherList.push({
              ...teacher,
              classType: "Group",
              className: group.name,
              classId: group.id,
            })
          );
        }

        setTeachers(teacherList);
      } catch (error) {
        console.error("Error fetching student data:", error);
        setError("Failed to load data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    if (user.id) {
      fetchStudentData();
    }
  }, [user.id]);

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar isClasses />
      <div className="flex-1 p-10">
        <h1 className="text-4xl font-bold mb-8 text-gray-800">Your Teachers</h1>
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        ) : error ? (
          <div
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded"
            role="alert"
          >
            <p>{error}</p>
          </div>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="grid grid-cols-1 gap-6"
          >
            {teachers.length > 0 ? (
              <ul className="space-y-4">
                {teachers.map((teacher, index) => (
                  <motion.li
                    key={index}
                    variants={itemVariants}
                    className="bg-white rounded-lg shadow-lg p-4 flex items-start space-x-4 hover:bg-gray-200 transition duration-300"
                  >
                    <FaChalkboardTeacher className="text-3xl text-blue-500 mt-1" />
                    <div>
                      <h3 className="text-lg font-semibold text-gray-700">
                        {teacher.name}
                      </h3>
                      <p className="text-gray-500">{teacher.email}</p>
                      <p className="text-sm text-gray-400 mt-1">
                        {teacher.classType}: {teacher.className}
                      </p>
                      {/* Link to the StudentClass page */}
                      <Link
                        to={`/student/${
                          teacher.userId
                        }/${teacher.classType.toLowerCase()}/${
                          teacher.classId
                        }`}
                        className="text-blue-500 underline"
                      >
                        View Lectures
                      </Link>
                    </div>
                  </motion.li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 italic">No teachers found.</p>
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
}
