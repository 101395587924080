export default function Careers() {
  const careerData = [
    {
      id: 1,
      title: "Entry-Level Opportunities",
      description:
        "After completing the first two years, students will have a solid foundation in mathematics, physics, chemistry, and computer science. This knowledge prepares them for entry-level positions such as research assistants, laboratory technicians, or quality control analysts in industries related to nanoscience and nanotechnology.",
    },
    {
      id: 3,
      title: "Nanoscience and Nanotechnology Specialist",
      description:
        "As students progress into the third and fourth years, they gain expertise in nanofabrication or nanomaterials. Graduates can pursue roles as specialists in nanoscience and nanotechnology, working on cutting-edge projects in research institutions, private companies, or government agencies.",
    },
    {
      id: 5,
      title: "Advanced Research and Development",
      description:
        "Students completing the fifth and sixth semesters will be well-equipped for advanced research and development roles. Opportunities include working on projects involving nanomaterial synthesis, characterization, and applications. Graduates may contribute to groundbreaking advancements in various industries.",
    },
    {
      id: 7,
      title: "Nanotechnology Industry Professionals",
      description:
        "The seventh and eighth semesters focus on specialization and industry applications. Graduates are prepared for roles in the nanotechnology industry, such as nanofabrication engineers, device designers, or quality assurance specialists. They may also explore entrepreneurship opportunities.",
    },
    {
      id: 9,
      title: "Leadership and Innovation",
      description:
        "In the ninth semester, students engage in a final project, showcasing their ability to apply knowledge to real-world challenges. Graduates may transition into leadership roles, innovation management, or continue their entrepreneurial journey by launching startups or joining established companies as research leaders.",
    },
    {
      id: 10,
      title: "Entrepreneurship and Research Excellence",
      description:
        "The tenth semester culminates in a comprehensive final project. Graduates are well-prepared for entrepreneurship, establishing their ventures, or leading research teams. They may also pursue doctoral studies for research excellence and academic contributions.",
    },
  ];

  return (
    <div className="min-h-screen">
      <div className="container py-16 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="text-center pb-12">
            <h2 className="text-3xl font-semibold text-gray-900">
              Career Opportunities
            </h2>
            <p className="mt-4 text-gray-600">
              Explore the potential career paths and opportunities after each
              semester at the National School of Nanoscience and
              Nanotechnology.
            </p>
          </div>

          {/* Career Cards */}
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {careerData.map((career) => (
              <div
                key={career.id}
                className="bg-gray-50 p-6 rounded-lg shadow-lg shadow-gray-300"
              >
                <h3 className="text-lg font-semibold text-gray-900">
                  {career.title}
                </h3>
                <p className="mt-4 text-gray-600">{career.description}</p>
              </div>
            ))}
          </div>

          {/* Back to Program of Study Link */}
          <div className="mt-8 text-center">
            {/*<Link href="/program" className="text-primary hover:underline">
              Go to Program of Study
            </Link>*/}
          </div>
        </div>
      </div>
    </div>
  );
}
