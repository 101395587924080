import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import NewMobileMenu from "./newMobileMenu";
import { MdLanguage, MdExpandMore } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "en", label: "English" },
  { code: "ar", label: "Arabic" },
  { code: "fr", label: "French" },
];

const schoolDescriptions = [
  { title: "Faculty Members", description: "Explore", path: "/faculty" },
];

export default function NewHeader({
  isField = false,
  isProgram = false,
  isDoors = false,
  isConsultations = false,
  isPlatforms = false,
  isAbout = false,
  isContact = false,
}) {
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  // Language state and function
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
    i18n.changeLanguage(newLanguage.code);
  };

  // Dropdown menu state
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // Handle click outside dropdown to close it
  const dropdownRef1 = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setShowDropdown1(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <header
      dir="ltr"
      className={`w-full fixed flex flex-col items-center justify-center rounded-b-md font-sans z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top ? "bg-[#228FAE] text-white" : "text-[#177995]"
      }`}
    >
      <NavLink to="/">
        <div
          className={`hidden md:block sm:w-20 md:w-24 lg:w-28 xl:w-36 font-bold absolute top-0 left-3 text-xl p-5 rounded-b-[2.5rem] bg-white text-black shadow-xl transition-all duration-300 ${
            top ? "opacity-100" : "opacity-0 -translate-y-10"
          }`}
        >
          <img
            alt="logo"
            src="/full_logo.png"
            className="w-full h-auto object-cover"
          />
        </div>
      </NavLink>
      <nav
        className={`w-full hidden md:flex items-center ml-auto px-4 transition-all duration-300 ${
          !top
            ? "bg-[#228FAE] opacity-0 -translate-y-10"
            : "bg-gray-100 opacity-100"
        }`}
      >
        <ul className="flex items-center ml-auto space-x-1">
          {/*<li>
            <NavLink
              to="/virtualtours"
              className={`${
                isDoors && "bg-[#167996] text-white"
              } hover:bg-[#167996]/70 px-5 py-2 rounded-md hover:text-white block font-bold text-[15px]`}
            >
              {t("Virtual Tours")}
            </NavLink>
          </li>*/}
          <li>
            <NavLink
              to="/consultations"
              className={`${
                isConsultations && "bg-[#167996] text-white"
              } hover:bg-[#167996]/70 px-5 py-2 rounded-md hover:text-white block font-bold text-[15px]`}
            >
              {t("Consultations")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/login"
              className="hover:bg-[#167996]/70 px-5 py-2 rounded-md hover:text-white block font-bold text-[15px]"
            >
              <span>{t("Platform")}</span>
            </NavLink>
          </li>
        </ul>
      </nav>
      <nav className="w-full hidden md:flex items-center ml-auto py-2 px-4">
        <ul className="max-w-3/4 flex items-center ml-auto space-x-1">
          {/* School dropdown menu */}
          <div
            className="relative"
            onMouseEnter={() => setShowDropdown1(true)}
            onMouseLeave={() => setShowDropdown1(false)}
          >
            <button className="flex items-center font-bold text-[15px] px-5 py-2 rounded-md hover:bg-[#167996]/70 hover:text-white">
              {t("The School")} <MdExpandMore className="ml-2" />
            </button>
            {showDropdown1 && (
              <div
                className="absolute z-10 left-0 top-full w-64 bg-white rounded-md shadow-lg p-4"
                ref={dropdownRef1}
              >
                <ul>
                  {schoolDescriptions.map((item) => (
                    <li key={item.title} className="mb-3">
                      <NavLink to={item.path} className="block">
                        <h4 className="font-bold text-sm hover:text-[#167996]">
                          {t(item.title)}
                        </h4>
                        <p className="text-xs text-gray-600 hover:text-[#167996]">
                          {t(item.description)}
                        </p>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <li>
            <NavLink
              to="/field"
              className={`${
                isField && "bg-[#167996] text-white"
              } hover:bg-[#167996]/70 px-5 py-2 rounded-md hover:text-white block font-bold text-[15px]`}
            >
              {t("Our Field")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/program"
              className={`${
                isProgram && "bg-[#167996] text-white"
              } hover:bg-[#167996]/70 px-5 py-2 rounded-md hover:text-white block font-bold text-[15px]`}
            >
              {t("Program")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/platforms"
              className={`${
                isPlatforms && "bg-[#167996] text-white"
              } hover:bg-[#167996]/70 px-5 py-2 rounded-md hover:text-white block font-bold text-[15px]`}
            >
              {t("Ministry's Platforms")}
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="/about"
              className={`${
                isAbout && "bg-[#167996] text-white"
              } hover:bg-[#167996]/70 px-5 py-2 rounded-md hover:text-white block font-bold text-[15px]`}
            >
              {t("About")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={`${
                isContact && "bg-[#167996] text-white"
              } hover:bg-[#167996]/70 px-5 py-2 rounded-md hover:text-white block font-bold text-[15px]`}
            >
              {t("Contact")}
            </NavLink>
          </li>

          {/* Language switcher */}
          <div
            className="relative"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <button className="flex items-center p-3 rounded-md hover:bg-[#ECECEC]">
              <HiDotsVertical />
            </button>
            {showDropdown && (
              <ul className="absolute right-0 top-full mt-2 bg-white rounded-md shadow-lg">
                {languages.map((lang) => (
                  <li key={lang.code}>
                    <button
                      onClick={() => handleLanguageChange(lang)}
                      className="w-full text-gray-900 block px-5 py-2 hover:bg-gray-200 text-lg rounded-md"
                    >
                      {lang.label}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </ul>
      </nav>
      <NewMobileMenu />
    </header>
  );
}
