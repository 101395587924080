import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

// Replace with your actual image URLs
import nanoscienceImage1 from "./assets/f1.jpg";
import nanoscienceImage2 from "./assets/f2.jpg";
import nanotechnologyImage1 from "./assets/f4.jpg";
import nanotechnologyImage2 from "./assets/f3.jpg";
import NewHeader from "./Components/newHeader";
import { useTranslation } from "react-i18next";

import openDoorsImage from "./assets/open.png";
import schoolVideo from "./assets/tour/school.mp4";
import amphitheaterImage from "./assets/tour/amphitheater.jpg";
import classesImage from "./assets/tour/classes.jpg";
import openSpaceVideo from "./assets/tour/openSpace.mp4";
import clubActivitiesVideo from "./assets/tour/clubActivities.mp4";
import residenceImage from "./assets/tour/residence.jpg";
import ReactPlayer from "react-player/youtube";

export default function About() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1200, // Adjust animation duration as needed
    });
  }, []);

  const videoRefs = useRef([]);

  return (
    <div className="min-h-screen flex flex-col bg-default text-gray-950">
      <NewHeader isAbout />
      <section className="container mx-auto px-4 py-4 pt-32">
        <div className="flex justify-center">
          <h1 className="max-w-4xl text-wrap text-4xl font-[Lexend] text-center font-black leading-tighter tracking-tighter text-primary mb-8">
            {t("About Us")}
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-right">
            <img
              src={nanoscienceImage1}
              alt="Nanoscience"
              className="rounded-md shadow-lg"
            />
          </div>
          <div data-aos="fade-left">
            <h2 className="text-3xl font-bold mb-4 text-secondary">
              {t("The Scientific and Technological Hub - Abdelhafid Ihddaden")}
            </h2>
            <p className="text-lg">
              {t(
                "A project relied upon by the Algerian state, which was embodied and launched in 2021, and currently includes four higher schools in modern disciplines: the National School of Nanoscience and Nanotechnology, the National School of Artificial Intelligence, the National School of Mathematics, and the National School of Autonomous Systems. In addition to the National School of Cybersecurity, which will open its doors in the next university year."
              )}
            </p>
            <p className="text-lg">
              {t(
                "Four higher schools follow the classical system. Their goal is to train state engineers who are able to bear the challenges of the times and keep up with current developments. We mention that the National School of Nanoscience and Nanotechnology, as well as the National School of Autonomous Systems, follow a basic training in science and technology (ST). As for the National School for Artificial Intelligence and the National School for Mathematics, the basic training followed is a basic training in mathematics and automated information (MI)."
              )}
            </p>
          </div>
        </div>
      </section>

      <section className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center md:order-2">
          <div data-aos="fade-right">
            <h2 className="text-3xl font-bold mb-4 text-secondary">
              {t("The School")}
            </h2>
            <p className="text-lg">
              {t(
                "The National School of Nanoscience and Nanotechnology is a higher school that has opened its doors to the selected elite of the Algerian students in formation of efficient and experienced engineers in the futuristic field being one of the cutting-edge technologies and the fastest developing and spreading sciences in the world, this project is one of those representing the future vision of the Algerian higher education field, and a demonstration of the commitment of Algerian to the continuous development of the scientific level and technological advancement in the country. If you look for an academic adventure and hold the future in your hands, this national school is your place."
              )}
            </p>
          </div>
          <div data-aos="fade-left">
            <video
              ref={(el) => (videoRefs.current[2] = el)}
              className="mx-auto mb-8 rounded-lg shadow-lg"
              width="100%"
              height="auto"
              autoPlay
              loop
              muted
            >
              <source src={schoolVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      {/* Amphitheaters Section */}
      <section className="container mx-auto px-4 py-16 md:py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-right">
            <img
              src={amphitheaterImage}
              alt="Amphitheater"
              className="rounded-lg shadow-lg mx-auto mb-8"
              width="100%"
              height="auto"
            />
          </div>
          <div data-aos="fade-left">
            <h2 className="text-3xl font-bold mb-4 text-secondary">
              {t("Amphitheaters")}
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed">{t("")}</p>
          </div>
        </div>
      </section>

      {/* Classes Section */}
      <section className="container mx-auto px-4 py-16 md:py-24 bg-gray-100">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-right">
            <h2 className="text-3xl font-bold mb-4 text-secondary">
              {t("Classes")}
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed">{t("")}</p>
          </div>
          <div data-aos="fade-left">
            <img
              src={classesImage}
              alt="Classes"
              className="rounded-lg shadow-lg mx-auto mb-8"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </section>

      {/* Open Space Section */}
      <section className="container mx-auto px-4 py-16 md:py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-right">
            <video
              ref={(el) => (videoRefs.current[1] = el)}
              className="rounded-lg shadow-lg mx-auto mb-8"
              width="100%"
              height="auto"
              autoPlay
              loop
              muted
            >
              <source src={openSpaceVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div data-aos="fade-left">
            <h2 className="text-3xl font-bold mb-4 text-secondary">
              {t("Open Space")}
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed">{t("")}</p>
          </div>
        </div>
      </section>

      {/* Residence Section */}
      <section className="container mx-auto px-4 py-16 md:py-24 bg-gray-100">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-right">
            <h2 className="text-3xl font-bold mb-4 text-secondary">
              {t("Residence")}
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed">{t("")}</p>
          </div>
          <div data-aos="fade-left">
            <img
              src={residenceImage}
              alt="Residence"
              className="rounded-lg shadow-lg mx-auto mb-8"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </section>

      {/*<section className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-right">
            <img
              src={nanoscienceImage2}
              alt="Nanoscience"
              className="rounded-md shadow-lg"
            />
          </div>
          <div data-aos="fade-left">
            <h2 className="text-3xl font-bold mb-4 text-secondary">
            {t("The Halls")}
            </h2>
            <p className="text-lg">{t("The Halls")}</p>
          </div>
        </div>
      </section>

      <section className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center md:order-2">
          <div data-aos="fade-right">
            <h2 className="text-3xl font-bold mb-4 text-secondary">
            {t("The residence")}
            </h2>
            <p className="text-lg">{t("The residence")}</p>
          </div>
          <div data-aos="fade-left">
            <img
              src={nanotechnologyImage2}
              alt="Nanotechnology"
              className="rounded-md shadow-lg"
            />
          </div>
        </div>
      </section>*/}
    </div>
  );
}
