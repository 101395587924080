import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Careers from "./Careers";
import Conditions from "./Conditions";
import Contact from "./Contact";
import History from "./History";
import Platforms from "./Platforms";
import Program from "./Program";
import Header from "./Components/header";
import Footer from "./Components/footer";
import LoginPage from "./Login";
import ConsultationsListPage from "./ConsultationsListPage";
import { useStore } from "./store";
import AdminDashboard from "./Admin/Dashboard";
import AdminConsultations from "./Admin/Consultations";
import NewHome from "./NewHome";
import NewHeader from "./Components/newHeader";
import FieldPage from "./Field";
import AdminData from "./Admin/Data";
import AdminNews from "./Admin/News";
import NewsDetails from "./NewsDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TeacherDashboard from "./Teacher/Dashboard";
import StudentDashboard from "./Student/Dashboard";
import Profile from "./Profile";
import Faculty from "./Faculty";
import OpenDoors from "./OpenDoors";

import { DirectionContext } from "./DirectionContext";
import { useContext } from "react";
import StudentClasses from "./Student/Classes";
import AdminTeachers from "./Admin/Teachers";
import TeacherClasses from "./Teacher/Classes";
import TeacherClass from "./Teacher/Class";
import StudentClass from "./Student/Class";

function App() {
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);

  const direction = useContext(DirectionContext);

  return (
    <div dir={direction} className="bg-default min-h-screen overflow-x-hidden">
      <Router>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          {/* Public Routes */}
          <Route path="/profile/:id">
            <Profile />
          </Route>
          <Route path="/news/:id">
            <NewsDetails />
          </Route>
          {/*<Route path="/virtualtours">
            <OpenDoors />
          </Route>*/}
          <Route path="/about">
            <About />
          </Route>
          <Route path="/consultations">
            <ConsultationsListPage />
          </Route>
          <Route path="/field">
            <FieldPage />
          </Route>
          <Route path="/careers">
            <Careers />
          </Route>
          <Route path="/conditions">
            <Conditions />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/platforms">
            <Platforms />
          </Route>
          <Route path="/program">
            <Program />
          </Route>
          <Route path="/faculty">
            <Faculty />
          </Route>
          <Route exact path="/">
            <NewHome />
          </Route>

          {/* Protected Routes - Redirect if not logged in */}
          <Route path="/login">
            {isLoggedIn ? (
              user.type === "admin" ? (
                <Redirect to="/admin/dashboard" />
              ) : user.type === "teacher" ? (
                <Redirect to="/teacher/dashboard" />
              ) : user.type === "student" ? (
                <Redirect to="/student/dashboard" />
              ) : null
            ) : (
              <LoginPage />
            )}
          </Route>

          {/* Admin Routes */}
          {isLoggedIn && user && user.type === "admin" && (
            <>
              <Route exact path="/admin/dashboard">
                <AdminDashboard />
              </Route>
              <Route path="/admin/consultations">
                <AdminConsultations />
              </Route>
              <Route path="/admin/data">
                <AdminData />
              </Route>
              <Route path="/admin/news">
                <AdminNews />
              </Route>
              <Route path="/admin/teachers">
                <AdminTeachers />
              </Route>
              {/* Redirect to admin dashboard if on other admin pages */}
              <Route path="*">
                <Redirect to="/admin/dashboard" />
              </Route>
            </>
          )}

          {/* Teacher Routes */}
          {isLoggedIn && user && user.type === "teacher" && (
            <>
              <Route exact path="/teacher/dashboard">
                <TeacherDashboard />
              </Route>
              <Route exact path="/teacher/classes">
                <TeacherClasses />
              </Route>
              <Route exact path="/teacher/:type/:id">
                <TeacherClass />
              </Route>
              {/* Redirect to teacher dashboard if on other teacher pages */}
              <Route path="*">
                <Redirect to="/teacher/dashboard" />
              </Route>
            </>
          )}

          {/* Student Routes */}
          {isLoggedIn && user && user.type === "student" && (
            <>
              <Route exact path="/student/dashboard">
                <StudentDashboard />
              </Route>
              <Route path="/student/classes">
                <StudentClasses />
              </Route>
              <Route exact path="/student/:teacherId/:type/:id">
                <StudentClass />
              </Route>
              {/* Redirect to student dashboard if on other student pages */}
              <Route path="*">
                <Redirect to="/student/dashboard" />
              </Route>
            </>
          )}

          {/* 404 Not Found Route */}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col bg-default text-gray-950">
      <NewHeader />
      <section className="h-screen w-full flex justify-center items-center">
        <div className="mx-auto px-4 py-20">
          <h1 className="text-4xl font-bold text-gray-800">
            404 - Page Not Found
          </h1>
          <p className="mt-4 text-gray-600">
            The page you are looking for does not exist.
          </p>
        </div>
      </section>
    </div>
  );
};
