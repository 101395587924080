import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "../constants";
import Sidebar from "./Sidebar";
import Papa from "papaparse";
import { motion } from "framer-motion";

// Framer Motion variants for animations
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.1, delayChildren: 0.2 },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
};

export default function AdminData() {
  const [file, setFile] = useState(null);
  const [userType, setUserType] = useState("student");

  const [studentsFile, setStudentsFile] = useState("");
  const [teachersFile, setTeachersFile] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);

  const getFiles = () => {
    axios
      .get(`${baseURL}/csvfile/student`)
      .then((i) => i.data.filename && setStudentsFile(i.data.filename))
      .catch((e) => console.log(e));

    axios
      .get(`${baseURL}/csvfile/teacher`)
      .then((i) => i.data.filename && setTeachersFile(i.data.filename))
      .catch((e) => console.log(e));
  };

  async function fetchStudentData() {
    if (studentsFile.length > 0) {
      const studentDataResponse = await fetch(
        `${baseURL}/uploads/${studentsFile}`
      );
      const studentCsvText = await studentDataResponse.text();
      const studentResults = Papa.parse(studentCsvText, { header: true });
      setStudentData(studentResults.data);
    }
  }

  async function fetchTeacherData() {
    if (teachersFile.length > 0) {
      const response = await fetch(`${baseURL}/uploads/${teachersFile}`);
      const reader = response.body.getReader();
      const result = await reader.read(); // raw array
      const decoder = new TextDecoder("utf-8");
      const csv = decoder.decode(result.value); // the csv text
      const results = Papa.parse(csv, { header: true }); // object with { data, errors, meta }
      const rows = results.data; // array of objects
      setTeacherData(rows);
    }
  }

  useEffect(() => {
    getFiles();
  }, []);

  useEffect(() => {
    console.log(`${baseURL}/uploads/${studentsFile}`);
    fetchStudentData();
    fetchTeacherData();
  }, [studentsFile, teachersFile]);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please select a CSV file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("userType", userType);

    try {
      await axios.post(`${baseURL}/upload-csv`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setFile(null); // Reset file state
      getFiles();
      alert("CSV file uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert(`Error uploading file. ${error}`);
    }
  };

  return (
    <div className="flex min-h-screen text-gray-900">
      {/* Sidebar component */}
      <Sidebar isData />
      <div className="flex-grow flex flex-col items-center justify-center min-h-screen">
        <form
          onSubmit={onFormSubmit}
          className="p-6 mt-10 bg-white rounded shadow-md"
        >
          <input
            type="file"
            accept=".csv"
            onChange={onFileChange}
            className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
          />
          <select
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
          >
            <option value="student">Students</option>
            <option value="teacher">Teachers</option>
          </select>
          <button
            type="submit"
            className="w-full px-3 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline"
          >
            Upload
          </button>
        </form>
        {/* Display Student Data */}
        <motion.div
          variants={itemVariants}
          className="w-full max-w-2xl p-6 mt-6 bg-white rounded shadow-md"
        >
          <h1 className="text-2xl font-bold mb-2">Student Data</h1>
          <div className="overflow-x-auto">
            <table className="w-full whitespace-nowrap">
              <thead>
                <tr>
                  {studentData[0] &&
                    Object.keys(studentData[0]).map((heading, i) => (
                      <th
                        key={i}
                        className="px-4 py-2 text-left text-gray-500 uppercase tracking-wider"
                      >
                        {heading}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {studentData.map((row, i) => (
                  <motion.tr key={i} variants={itemVariants}>
                    {Object.keys(row).map((key, j) => (
                      <td key={j} className="px-4 py-2">
                        {row[key]}
                      </td>
                    ))}
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </motion.div>
        {/* Display Teacher Data */}
        <motion.div
          variants={itemVariants}
          className="w-full max-w-2xl p-6 mt-6 bg-white rounded shadow-md"
        >
          <h1 className="text-2xl font-bold mb-2">Teacher Data</h1>
          <div className="overflow-x-auto">
            <table className="w-full whitespace-nowrap">
              <thead>
                <tr>
                  {teacherData[0] &&
                    Object.keys(teacherData[0]).map((heading, i) => (
                      <th
                        key={i}
                        className="px-4 py-2 text-left text-gray-500 uppercase tracking-wider"
                      >
                        {heading}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {teacherData.map((row, i) => (
                  <motion.tr key={i} variants={itemVariants}>
                    {Object.keys(row).map((key, j) => (
                      <td key={j} className="px-4 py-2">
                        {row[key]}
                      </td>
                    ))}
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
