import React from "react";
import Map from "./Components/map/map";
import NewHeader from "./Components/newHeader";
import { useTranslation } from "react-i18next";
import { FiMapPin } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col">
      <NewHeader isContact />
      <div className="container py-16 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="text-center pb-8">
            <h2 className="text-4xl font-[Lexend] text-center font-black leading-tighter tracking-tighter mb-4 text-primary">
              {t("Contact us")}
            </h2>
          </div>
          <div className="grid lg:grid-cols-2 gap-12">
            <div className="space-y-8">
              <h2 className="text-2xl font-semibold text-gray-900">
                {t("Get in Touch")}
              </h2>
              <p className="text-gray-600">
                {t("We welcome your inquiries, comments, and suggestions.")}
              </p>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-4">
                  <FiMapPin size={20} className="text-primary mx-2" />
                  <div>
                    <h3 className="font-semibold text-gray-900">
                      {t("Address")}:
                    </h3>
                    <p className="text-gray-600">
                      {t("Algiers, Sidi Abdellah, Mahelma")}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <MdOutlineMail size={20} className="text-primary mx-2" />
                  <div>
                    <h3 className="font-semibold text-gray-900">
                      {t("Email Address")}:
                    </h3>
                    <p className="text-gray-600">ensnn2023@gmail.com</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <FiPhoneCall size={20} className="text-primary mx-2" />
                  <div>
                    <h3 className="font-semibold text-gray-900">
                      {t("Phone Number")}:
                    </h3>
                    <p className="text-gray-600" dir="ltr">023 08 36 70</p>
                  </div>
                </div>
                <Map />
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                {t("Send Us a Message")}
              </h2>
              <form className="space-y-8">
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <label className="block text-gray-600 mb-2" htmlFor="name">
                      {t("Name")}:
                    </label>
                    <input
                      type="text"
                      className="w-full text-black px-4 py-2 border rounded-md focus:border-primary focus:ring-primary"
                      id="name"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-600 mb-2" htmlFor="email">
                      {t("Email")}:
                    </label>
                    <input
                      type="email"
                      className="w-full text-black px-4 py-2 border rounded-md focus:border-primary focus:ring-primary"
                      id="email"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-600 mb-2"
                      htmlFor="subject"
                    >
                      {t("Subject")}:
                    </label>
                    <input
                      type="text"
                      className="w-full text-black px-4 py-2 border rounded-md focus:border-primary focus:ring-primary"
                      id="subject"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-600 mb-2"
                      htmlFor="message"
                    >
                      {t("Message")}:
                    </label>
                    <textarea
                      className="w-full text-black px-4 py-2 border rounded-md focus:border-primary focus:ring-primary"
                      id="message"
                      rows="6"
                    ></textarea>
                  </div>
                </div>
                <button
                  //type="submit"
                  className="w-full px-4 py-2 font-semibold text-white transition-colors duration-200 bg-primary rounded-md hover:bg-primary/70"
                >
                  {t("Send Message")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
