import { GoHomeFill } from "react-icons/go";
import { IoDocuments } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "../store";

export default function Sidebar({
  isHome = false,
  isClasses = false,
  isPlanner = false,
}) {

  const setIsLoggedIn = useStore((state) => state.setIsLoggedIn);
  const setUser = useStore((state) => state.setUser);

  const history = useHistory();

  const handleLogout = async () => {
    setIsLoggedIn(false);
    setUser(null);

    history.replace("/");
  };

  return (
    <div className="flex flex-col items-center bg-tertiary px-5 py-24 m-6 mr-10 rounded-3xl">
      <div className="flex flex-col items-center">
        <Link
          to="/student/dashboard"
          className={`${
            isHome && "bg-primary"
          } flex items-center space-x-2 rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          <GoHomeFill className="h-8 w-8" />
          <div className="hidden md:flex">Dashboard</div>
        </Link>

        <Link
          to="/student/classes"
          className={`${
            isClasses && "bg-primary"
          } flex items-center space-x-2 rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          <IoDocuments className="h-8 w-8" />
          <div className="hidden md:flex">Classes</div>
        </Link>

        <Link
          to="/student/planner"
          className={`${
            isPlanner && "bg-primary"
          } flex items-center space-x-2 rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          <FaCalendarAlt className="h-8 w-8" />
          <div className="hidden md:flex">Planner</div>
        </Link>

        <button
          className="rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
}
