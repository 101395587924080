import React, { useState } from "react";
import { baseURL } from "../constants";
import Sidebar from "./Sidebar";

export default function AdminConsultations() {
  const [title, setTitle] = useState("");
  const [type, setType] = useState("Consultation");
  const [pdfFile, setPdfFile] = useState(null);
  const [msg, setMsg] = useState("");

  const [previewFile, setPreviewFile] = useState(null);

  const handleSubmitFile = async (e) => {
    e.preventDefault();

    if (!pdfFile) {
      setMsg("Please select a PDF file.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("type", type);
    formData.append("pdfFile", pdfFile);

    try {
      const response = await fetch(`${baseURL}/upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      setMsg(data.message);
      // Clear form fields after successful upload
      setTitle("");
      setType("");
      setPdfFile(null);
    } catch (error) {
      setMsg("Error: " + error.message);
    }
  };

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
    setPreviewFile(URL.createObjectURL(e.target.files[0])); // Create preview URL
  };
  return (
    <div className="flex min-h-screen">
      <Sidebar isConsultation />
      <div className="min-h-screen bg-default">
        <div className="container py-16 mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-8">
            <h2 className="text-3xl font-semibold text-gray-900 mb-6">
              Admin - Upload Consultation
            </h2>

            {msg && <p className="text-blue-700 text-center mb-4">{msg}</p>}

            <form onSubmit={handleSubmitFile} className="space-y-6">
              {/* Title Input */}
              <div>
                <label
                  htmlFor="title"
                  className="block text-gray-700 font-medium"
                >
                  Title:
                </label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="w-full text-gray-800 placeholder-gray-700 px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                />
              </div>

              {/* Type Select */}
              <div>
                <label
                  htmlFor="type"
                  className="block text-gray-700 font-medium"
                >
                  Type:
                </label>
                <select
                  id="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  required
                  className="w-full text-gray-800 placeholder-gray-700 px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                >
                  <option value="Consultation">Consultation</option>
                  <option value="Provisional Allocation">
                    Provisional Allocation
                  </option>
                </select>
              </div>

              {/* PDF File Input */}
              <div>
                <label
                  htmlFor="pdfFile"
                  className="block text-gray-700 font-medium"
                >
                  PDF File:
                </label>
                <input
                  type="file"
                  id="pdfFile"
                  accept=".pdf"
                  onChange={handleFileChange}
                  required
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                />
              </div>

              {/* File Preview (if available) */}
              {previewFile && (
                <div className="border rounded-md p-4">
                  <iframe
                    src={previewFile}
                    title="PDF Preview"
                    className="w-full h-64" // Adjust height as needed
                  ></iframe>
                </div>
              )}

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full px-6 py-3 font-medium text-white bg-primary rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                Upload
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
