import React, { useEffect, useRef, useState } from "react";
import NewHeader from "./Components/newHeader";
import HeroImg from "./assets/hero-image.png";
import VideoThumb from "./assets/hero-image.png";
import Intro from "./assets/intro.mp4";
import F1 from "./assets/f1.jpg";
import F5 from "./assets/f5.jpg";
import F3 from "./assets/f3.jpg";
import F4 from "./assets/f4.jpg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoArrowRedoSharp } from "react-icons/io5";
import { SiGoogleearthengine } from "react-icons/si";
import { HiUserGroup } from "react-icons/hi";
import { FaNewspaper } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdOutlineElectricBolt } from "react-icons/md";
import { Link } from "react-router-dom";
import ModalVideo from "./Components/modal-video";
import axios from "axios";
import { baseURL } from "./constants";
import { useTranslation } from "react-i18next";

export default function NewHome() {
  //const images = [News1, News2];

  const { t } = useTranslation();

  const [news, setNews] = useState([]);
  const [activeNews, setActiveNews] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${baseURL}/news`);
        const newsFiltered = response.data.filter((item) => item.hasImg);

        setNews(newsFiltered);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    // Start the carousel only if there are news
    if (news.length > 0) {
      intervalRef.current = setInterval(() => {
        setActiveNews((prevActive) => (prevActive + 1) % news.length);
      }, 3000);
    }

    // Clear the interval when the component unmounts or news array changes
    return () => clearInterval(intervalRef.current);
  }, [news]);

  const handlePrevClick = () => {
    setActiveNews((prevActive) => (prevActive - 1 + news.length) % news.length);
  };

  const handleNextClick = () => {
    setActiveNews((prevActive) => (prevActive + 1) % news.length);
  };

  // Features component state and functions
  const [tab, setTab] = useState(1);
  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current && tabs.current.parentElement) {
      tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`;
    }
  };

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1200,
    });
    heightFix(); // Call heightFix on initial render
  }, [tab]); // Re-run heightFix when the tab changes

  return (
    <div dir="ltr" className="min-h-screen flex flex-col">
      <NewHeader />
      <section className="relative bg-white">
        <div className="flex flex-col md:flex-row items-center justify-center px-6">
          <div className="pt-32 flex flex-col md:flex-row items-center justify-evenly">
            <div className="flex-[0.5] mx-7">
              <h1
                className="text-3xl font-[Lexend] text-center font-black leading-tighter tracking-tighter mb-4 text-primary"
                data-aos="zoom-y-out"
              >
                {t(
                  "Discover the realm of science and technology at National School of Nanoscience and Nanotechnology"
                )}
              </h1>
              <p
                className="text-xl text-center text-gray-400 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                {t(
                  "Our National School Will Make a Revolution in Different Technology Fields"
                )}
              </p>{" "}
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                {" "}
                <div>
                  {" "}
                  <a
                    className="btn text-white bg-primary hover:bg-primary/60 w-full mb-4 sm:w-auto sm:mb-0"
                    href="/login"
                  >
                    {t("Log in to your account")}
                  </a>{" "}
                </div>{" "}
                <div>
                  {" "}
                  <Link
                    className="btn text-white bg-tertiary hover:bg-tertiary/70 w-full sm:w-auto sm:ml-4"
                    to="/about"
                  >
                    {t("Find more about us")}
                  </Link>{" "}
                </div>{" "}
              </div>
            </div>
            <div className="flex-[0.5] md:mr-4 mt-4 md:mt-0">
              <ModalVideo
                thumb={VideoThumb}
                thumbWidth={900}
                thumbHeight={600}
                thumbAlt="Nanotech video thumbnail"
                video={Intro}
                videoWidth={1080}
                videoHeight={1920}
              />
            </div>
          </div>
        </div>
      </section>
      {/*<div className="flex flex-col md:flex-row pt-12 md:pt-32 px-4 md:px-12">
        <div
          className="flex flex-col md:w-1/2 flex-[0.57]"
          data-aos="fade-right"
        >
          <div className="flex mb-6">
            <div className="w-full mr-4" data-aos="fade-right">
              <img
                src={HeroImg}
                alt="Hero Image"
                className="w-full h-72 rounded-2xl shadow-md object-cover"
              />
            </div>
            <div className="w-full md:w-1/4" data-aos="fade-left">
              <img
                src={F5}
                alt="F2 Image"
                className="w-full h-72 rounded-2xl shadow-md object-cover"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-9">
            <Link
              to={"/about"}
              className="bg-white flex flex-col justify-center items-center rounded-md p-6 shadow-md transition-all duration-300 hover:shadow-xl text-center text-gray-700 hover:text-gray-900"
              data-aos="fade-up"
            >
              <h3 className="text-lg font-semibold mb-2">About the School</h3>
              <p className="text-sm">Find info about ENSNN</p>
            </Link>
            <Link
              to={"/field"}
              className="bg-white flex flex-col justify-center items-center rounded-md p-6 shadow-md transition-all duration-300 hover:shadow-xl text-center text-gray-700 hover:text-gray-900"
              data-aos="fade-up"
            >
              <h3 className="text-lg font-semibold mb-2">About our Field</h3>
              <p className="text-sm">
                Find information about the tool of future
              </p>
            </Link>
          </div>
        </div>
        <div
          className="hidden md:flex flex-[0.43] flex-col items-center relative"
          data-aos="fade-left"
        >
          {/* Placeholder Logo */}
      {/*<div className="w-44 h-60 bg-white rounded-3xl flex items-center justify-center text-4xl font-bold text-gray-700 shadow-lg">
            Logo
          </div>
          {/* Text Overlays */}
      {/*<div
            className="absolute bg-gray-200 rounded-t-2xl rounded-l-2xl shadow-md p-2 font-semibold text-gray-700 transform transition duration-500 hover:scale-110"
            style={{ top: `${-10}%`, left: `${15}%` }}
          >
            180 students
          </div>
          <div
            className="absolute bg-gray-200 rounded-t-2xl rounded-r-2xl shadow-md p-2 font-semibold text-gray-700 transform transition duration-500 hover:scale-110"
            style={{ top: `${0}%`, left: `${65}%` }}
          >
            20 teachers
          </div>
          <div
            className="absolute bg-gray-200 rounded-b-2xl rounded-r-2xl shadow-md p-2 font-semibold text-gray-700 transform transition duration-500 hover:scale-110"
            style={{ top: `${60}%`, left: `${65}%` }}
          >
            0 graduates
          </div>
          <div
            className="absolute bg-gray-200 rounded-b-2xl rounded-l-2xl shadow-md p-2 font-semibold text-gray-700 transform transition duration-500 hover:scale-110"
            style={{ top: `${53}%`, left: `${8}%` }}
          >
            2 scientific clubs
          </div>
        </div>
      </div>*/}
      <div
        className="flex justify-center items-center my-20"
        data-aos="zoom-in"
      >
        <div className="bg-[#15718B] p-4 w-full">
          <h2 className="text-white font-bold text-xl text-center mb-4">
            <span>{t("News")}</span>
          </h2>
          {news.length > 0 ? (
            <div className="overflow-hidden rounded-md flex justify-center items-center space-x-4 md:space-x-20">
              <div className="text-white text-4xl cursor-pointer">
                <FaChevronLeft onClick={handlePrevClick} />
              </div>
              <div className="flex space-y-3 flex-col justify-center items-center w-full">
                <img
                  src={baseURL + news[activeNews]["imagePath"]}
                  alt="News Image"
                  className="w-full md:w-2/4 h-auto md:h-full object-cover rounded-md"
                />
                <Link
                  to={`/news/${news[activeNews]["id"]}`}
                  className="text-center text-white font-semibold text-base"
                >
                  {/*t("Find more")*/}
                </Link>
              </div>
              <div className="text-white text-4xl cursor-pointer">
                <FaChevronRight onClick={handleNextClick} />
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center text-lg text-center h-64 w-full">
              {t("Loading...")}
            </div>
          )}
        </div>
      </div>
      <div className="p-8">
        <h2 className="text-center text-3xl font-bold text-gray-800 mb-8">
          {t("Student Activities")}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div className="bg-white flex flex-col justify-center items-center rounded-md p-6 shadow-lg transition-all duration-300 hover:shadow-xl text-center text-gray-700 hover:text-gray-900">
            <HiUserGroup size={40} className="text-secondary mb-4" />
            <h4 className="text-xl font-bold text-gray-800 leading-snug tracking-tight mb-1">
              {t("Scientific Clubs")}
            </h4>
            <p className="text-gray-600">
              {t("Information that students will provide as soon as possible")}
            </p>
          </div>
          <div className="bg-white flex flex-col justify-center items-center rounded-md p-6 shadow-lg transition-all duration-300 hover:shadow-xl text-center text-gray-700 hover:text-gray-900">
            <SiGoogleearthengine size={40} className="text-secondary mb-4" />
            <h4 className="text-xl font-bold text-gray-800 leading-snug tracking-tight mb-1">
              {t("Projects")}
            </h4>
            <p className="text-gray-600">
              {t("Information that students will provide as soon as possible")}
            </p>
          </div>
          <div className="bg-white flex flex-col justify-center items-center rounded-md p-6 shadow-lg transition-all duration-300 hover:shadow-xl text-center text-gray-700 hover:text-gray-900">
            <FaNewspaper size={40} className="text-secondary mb-4" />
            <h4 className="text-xl font-bold text-gray-800 leading-snug tracking-tight mb-1">
              {t("Scientific Researches")}
            </h4>
            <p className="text-gray-600">
              {t("Information that students will provide as soon as possible")}
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center my-20">
        <Features tab={tab} setTab={setTab} tabs={tabs} />
      </div>
    </div>
  );
}

function Features({ tab, setTab, tabs }) {
  const { t } = useTranslation();
  return (
    <section className="bg-[#15718B] p-4 w-full text-white relative">
      <div
        className="absolute inset-0 pointer-events-none mb-16"
        aria-hidden="true"
      ></div>
      <div className="relative mx-auto px-4 sm:px-6">
        <div className="pt-10">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="h2 mb-2">{t("The Student Life")}</h2>
          </div>
          {/* Section content */}
          <div className="mx-auto p-4">
            <div className="flex flex-col items-center justify-center md:flex-row md:flex-wrap md:gap-6">
              {/* Content */}
              <div
                className="flex-1 md:w-1/2 lg:w-2/5 max-w-xl mx-auto md:mt-6 flex items-center justify-center"
                data-aos="fade-right"
              >
                {/* Tabs buttons */}
                <div className="mb-8 md:mb-0">
                  {[1, 2, 3].map((index) => (
                    <a
                      key={index}
                      className={`flex items-center text-gray-700 text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                        tab !== index
                          ? "bg-default shadow-md border-gray-300 hover:shadow-lg"
                          : "bg-gray-300 border-transparent"
                      }`}
                      href="#0"
                      onClick={(e) => {
                        e.preventDefault();
                        setTab(index);
                      }}
                      data-aos="fade-up"
                    >
                      <div>
                        {index === 1 ? (
                          <>
                            <div className="font-bold leading-snug tracking-tight mb-1">
                              {t("Sidi Abdellah technological center")}
                            </div>
                            <div className="text-gray-600">
                              {t(
                                "Sidi Abdellah is a new city in Algeria that aims to become a hub for science, technology, and innovation."
                              )}
                            </div>
                          </>
                        ) : index === 2 ? (
                          <>
                            <div className="font-bold leading-snug tracking-tight mb-1">
                              {t("Open Space of Studying in the school")}
                            </div>
                            <div className="text-gray-600">
                              {t(
                                "Open Space of Studying is a concept that promotes collaborative learning and creativity among students"
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="font-bold leading-snug tracking-tight mb-1">
                              {t("University residence")}
                            </div>
                            <div className="text-gray-600">
                              {t(
                                "Mehalma residence is a modern university residence that offers students a living experience"
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow-gray-200 shadow-md flex-shrink-0 ml-3">
                        <IoArrowRedoSharp
                          className="text-[#ffc400]"
                          size={18}
                        />
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              {/* Tabs items */}
              <div
                className="flex-1 md:w-1/2 lg:w-3/5 mx-auto mb-8 md:mb-0 md:order-1 flex items-center justify-center"
                data-aos="zoom-y-out"
              >
                <div className="relative flex flex-col text-center lg:text-right">
                  {[1, 2, 3].map((index) => (
                    <div
                      key={index}
                      className={tab === index ? "block" : "hidden"}
                      style={{ height: tab === index ? "auto" : 0 }}
                    >
                      <div className="relative inline-flex flex-col">
                        <img
                          className="mx-auto rounded-md w-full transition-all duration-200"
                          src={tab === 1 ? F1 : tab === 2 ? F4 : F3}
                          alt="Features bg"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
