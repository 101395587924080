import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import Sidebar from "./Sidebar";
import { useStore } from "../store";
import { baseURL } from "../constants";
import { FaChalkboardTeacher, FaUsers } from "react-icons/fa";

const containerVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, type: "spring", stiffness: 100 },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, type: "spring", stiffness: 100 },
  },
};

export default function TeacherClasses() {
  const user = useStore((state) => state.user);
  const [sections, setSections] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [sectionsRes, groupsRes] = await Promise.all([
          axios.get(`${baseURL}/teacher/${user.id}/sections`),
          axios.get(`${baseURL}/teacher/${user.id}/groups`),
        ]);
        setSections(sectionsRes.data);
        setGroups(groupsRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    if (user.id) {
      fetchData();
    }
  }, [user.id]);

  const CardWrapper = ({ title, icon, items, type }) => (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="bg-white rounded-xl shadow-lg p-6 mb-6"
    >
      <div className="flex items-center mb-4">
        {icon}
        <h2 className="text-2xl font-semibold text-gray-800 ml-3">{title}</h2>
      </div>
      {items.length > 0 ? (
        <ul className="space-y-2">
          {items.map((item) => (
            <motion.li
              key={item.id}
              variants={itemVariants}
              className="bg-gray-100 rounded-md p-3 flex justify-between items-center hover:bg-gray-200 transition duration-300"
            >
              <Link to={`/teacher/${type}/${item.id}`} className="flex-grow">
                <span className="text-gray-700">{item.name}</span>
              </Link>
            </motion.li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500 italic">
          No {title.toLowerCase()} assigned yet.
        </p>
      )}
    </motion.div>
  );

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar isClasses />
      <div className="flex-1 p-10">
        <h1 className="text-4xl font-bold mb-8 text-gray-800">My Classes</h1>
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        ) : error ? (
          <div
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded"
            role="alert"
          >
            <p>{error}</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <CardWrapper
              title="Sections"
              icon={<FaChalkboardTeacher className="text-3xl text-blue-500" />}
              items={sections}
              type="section"
            />
            <CardWrapper
              title="Groups"
              icon={<FaUsers className="text-3xl text-green-500" />}
              items={groups}
              type="group"
            />
          </div>
        )}
      </div>
    </div>
  );
}
