import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { FiUpload, FiTrash2 } from "react-icons/fi";
import { baseURL } from "../constants";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify"; 

// Framer Motion animations
const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: { y: "0", opacity: 1 },
  exit: { y: "100vh", opacity: 0 },
};

const AdminNews = () => {
  const [news, setNews] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${baseURL}/news`);
        setNews(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
        toast.error("Error fetching news data."); 
      }
    };
    fetchNews();
  }, []);

  const handleDeleteNews = async (id) => {
    if (window.confirm("Are you sure you want to delete this news?")) {
      try {
        await axios.delete(`${baseURL}/news/${id}`);
        setNews(news.filter((item) => item.id !== id));
        toast.success("News deleted successfully!"); 
      } catch (error) {
        console.error("Error deleting news:", error);
        toast.error("Failed to delete news."); 
      }
    }
  };

  return (
    <div className="flex min-h-screen grow w-full">
      <Sidebar isNews />
      <main className="flex-grow py-16 px-4 sm:p-6 lg:p-8">
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="w-full min-h-screen py-16 flex flex-col space-y-3 items-center bg-default rounded-lg shadow-lg p-6"> 
            <h1 className="text-3xl font-bold text-gray-800">News Dashboard</h1>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" 
              onClick={() => setShowModal(true)}
            >
              Add News
            </button>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {news.length > 0 ? (
                news.map((newsItem) => (
                  <NewsRow key={newsItem.id} item={newsItem} onDelete={handleDeleteNews} />
                ))
              ) : (
                <div className="text-center font-bold">Nothing to show</div>
              )}
            </div>

            {/* Modal for adding new news */}
            <AnimatePresence
              initial={true}
              onExitComplete={() => setShowModal(false)}
            >
              {showModal && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 flex items-center justify-center z-50" 
                >
                  <NewNews
                    onClose={async () => {
                      const response = await axios.get(`${baseURL}/news`);
                      setNews(response.data);
                      setShowModal(false);
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </main>
    </div>
  );
};

const NewsRow = ({ item, onDelete }) => {
  return (
    <Link key={item.id} to={`/news/${item.id}`} className="relative"> 
      <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center hover:shadow-xl transition duration-150 ease-in-out"> 
        {item.hasImg && (
          <img
            src={`${baseURL}${item.imagePath}`}
            alt={item.title}
            width={270}
            height={200}
            className="object-cover rounded-lg mb-5"
          />
        )}
        <h2 className="text-xl font-bold text-gray-900 mb-3">{item.title}</h2>
        <p className="text-gray-700 mb-5">
          {new Date(item.date).toLocaleDateString("en-CA")}
        </p>
        <p className="text-gray-700 mb-5 line-clamp-3"> 
          {item.content}
        </p>
      </div>

      {/* Delete button */}
      <button 
        onClick={(e) => {
          e.preventDefault(); 
          onDelete(item.id);
        }}
        className="absolute top-4 right-4 p-1 rounded-full bg-red-500 hover:bg-red-600 text-white transition duration-150 ease-in-out" 
      >
        <FiTrash2 className="w-4 h-4" />
      </button>
    </Link>
  );
};

const NewNews = ({ onClose }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    if (file) {
      formData.append("image", file);
      formData.append("hasImg", true);
    } else {
      formData.append("hasImg", false);
    }
    try {
      await axios.post(`${baseURL}/news`, formData, {
        headers: { "content-type": "multipart/form-data" },
      });
      toast.success("News created successfully!"); 
      onClose(); 
    } catch (error) {
      console.error("Error creating news:", error);
      toast.error("Failed to create news."); 
    } finally {
      setLoading(false);
    }
  };

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50" 
      onClick={onClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="bg-white w-3/4 md:w-1/2 lg:w-1/3 p-8 rounded-lg flex flex-col items-center shadow-lg" 
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h1 className="text-2xl font-bold mb-4 text-blue-500">New News</h1>
        <form onSubmit={onSubmit} className="flex flex-col items-center w-full">
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            placeholder="Title ..."
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
          />
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Content ..."
            className="w-full px-4 py-2 mt-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
          />
          <div className="w-full mt-4">
            <label
              htmlFor="dropzone-file"
              className="w-full h-48 flex flex-col items-center justify-center border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-default transition duration-150 ease-in-out" 
            >
              <div className="flex flex-col items-center justify-center">
                <FiUpload className="w-8 h-8 mb-4 text-gray-500" />
                <p className="mb-2 text-sm text-gray-500">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                accept="image/*"
                onChange={onFileChange}
                className="hidden"
              />
            </label>
            {preview && (
              <img
                src={preview}
                alt="Preview"
                className="w-full h-48 object-cover rounded-lg mt-4"
              />
            )}
          </div>
          <button
            className="w-full py-2 mt-4 text-white bg-blue-500 hover:bg-blue-700 font-bold rounded-lg focus:outline-none focus:shadow-outline disabled:opacity-50 transition duration-150 ease-in-out" 
            type="submit"
            disabled={loading}
          >
            {loading ? "Loading..." : "Create"}
          </button>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default AdminNews;