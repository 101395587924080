import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosPeople } from "react-icons/io";
import { IoPeople, IoSchool, IoMoon, IoSunny } from "react-icons/io5";
import { FaSearch, FaTasks } from "react-icons/fa";
import ChartsRow from "./ChartsRow";
import Sidebar from "./Sidebar";
import { baseURL } from "../constants";
import Bl1 from "../assets/blur1.jpg";
import Bl2 from "../assets/blur2.jpg";
import Bl3 from "../assets/blur3.jpg";

export default function AdminDashboard() {
  const [studentsNum, setStudentsNum] = useState(0);
  const [teachersNum, setTeachersNum] = useState(0);
  const [sections, setSections] = useState([]);
  const [currentActivities, setCurrentActivities] = useState([]);

  const getData = async () => {
    try {
      const studentsResponse = await axios.get(`${baseURL}/students`);
      setStudentsNum(studentsResponse.data.length);

      const teachersResponse = await axios.get(`${baseURL}/teachers`);
      setTeachersNum(teachersResponse.data.length);

      const sectionsResponse = await axios.get(`${baseURL}/sections`);
      setSections(sectionsResponse.data);

      const activitiesResponse = await axios.get(
        `${baseURL}/sections/activities/current`
      );
      setCurrentActivities(activitiesResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={`flex min-h-screen bg-white`}>
      <Sidebar isHome />
      <main className="flex-grow py-16 px-4 sm:p-6 lg:p-8 transition-colors duration-300">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-semibold text-gray-800">Welcome, Admin</h1>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <FaSearch size={24} className="text-gray-800" />
              <input
                type="text"
                className="absolute inset-0 w-full pl-10 py-2 rounded-lg bg-gray-200 text-gray-800"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row lg:space-x-4 my-4">
          {/* Statistics Section */}
          <div className={`flex-1 bg-[${Bl1}] bg-cover bg-center rounded-lg shadow-lg`}>
            <div className="w-full h-full p-6 bg-white/70 backdrop-blur-lg hover:backdrop-blur-sm rounded-lg transition-all duration-300">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Statistics</h2>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                <div className="flex items-center justify-center space-x-2 rounded-lg px-7 py-5 bg-gray-100">
                  <div className="p-3 rounded-full bg-blue-500 text-white">
                    <IoIosPeople size={25} />
                  </div>
                  <div className="flex flex-col">
                    <h3 className="font-semibold text-lg text-gray-800">
                      Students
                    </h3>
                    <p className="text-base text-gray-800">{studentsNum}</p>
                  </div>
                </div>
                <div className="flex items-center justify-center space-x-2 rounded-lg px-7 py-5 bg-gray-100">
                  <div className="p-3 rounded-full bg-green-500 text-white">
                    <IoPeople size={25} />
                  </div>
                  <div className="flex flex-col">
                    <h3 className="font-semibold text-lg text-gray-800">
                      Teachers
                    </h3>
                    <p className="text-base text-gray-800">{teachersNum}</p>
                  </div>
                </div>
              </div>
              <ChartsRow students={studentsNum} teachers={teachersNum} />
            </div>
          </div>

          {/* Push Notifications Section */}
          <div className={`flex-1 bg-[${Bl2}] bg-cover bg-center rounded-lg shadow-lg`}>
            <div className="w-full h-full p-6 bg-white/70 backdrop-blur-lg hover:backdrop-blur-sm rounded-lg transition-all duration-300">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Push Notifications</h2>
              <div className="bg-gray-100 rounded-lg p-4 mb-4">
                {sections.map((section) => (
                  <div key={section.id} className="my-2 text-gray-800">
                    {section.name}
                  </div>
                ))}
              </div>
              {/* Notification sending logic can be implemented here */}
            </div>
          </div>
        </div>

        {/* Current Activities Section */}
        <div className={`flex-1 bg-[${Bl3}] bg-cover bg-center rounded-lg shadow-lg`}>
          <div className="w-full h-full p-6 bg-white/70 backdrop-blur-lg hover:backdrop-blur-sm rounded-lg transition-all duration-300">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">What's Happening Now</h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {currentActivities.map((activity) => (
                <div
                  key={activity.id}
                  className="flex items-center justify-between p-4 bg-gray-100 rounded-lg shadow-md"
                >
                  <div className="flex items-center space-x-2 text-gray-800">
                    <div className="p-2 rounded-full bg-red-500 text-white">
                      <IoSchool size={25} />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold">
                        {activity.sectionName}
                      </h3>
                      <p className="text-base">
                        {/* Display activity details here (e.g., time, teacher, subject) */}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Task Management Section */}
        <div className="flex-1 bg-white/70 backdrop-blur-lg hover:backdrop-blur-sm rounded-lg shadow-lg p-6 mt-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Task Management</h2>
          <div className="flex items-center space-x-2">
            <FaTasks size={24} className="text-blue-500" />
            <div className="flex-1 bg-gray-100 rounded-lg p-4">
              <p className="text-gray-800">No tasks available</p>
              {/* Implement task management logic here */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
