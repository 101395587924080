import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { FaSearch, FaBell } from "react-icons/fa";
import { motion } from "framer-motion";

export default function StudentDashboard() {
  const [classes, setClasses] = useState([]);

  return (
    <div className="flex min-h-screen grow w-full">
      <Sidebar isHome />

      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="h-full w-full flex flex-col pt-16 pl-2 pr-5"
      >
        {/* Welcome Message */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex flex-col">
            <div className="text-2xl font-semibold">Welcome, username</div>
          </div>
          <div className="flex space-x-2 mr-10">
            <FaSearch size={17} />
            <FaBell size={17} />
            <div>Profile</div>
          </div>
        </div>

        {/* Classes and News */}
        <div className="flex h-1/2">
          {/* Classes Section */}
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="flex-[0.35] h-full space-y-2 flex flex-col items-center bg-[#0e115f] px-5 py-10 m-6 mr-10 rounded-2xl"
          >
            <div className="text-xl text-center font-semibold m-2 text-white">
              Classes for the day
            </div>
            {classes.length > 0 ? (
              classes.map((item, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  className="flex flex-col justify-center bg-[#c2ffc0e2] p-3 w-full rounded-2xl space-y-3 mb-3"
                >
                  <div className="flex items-center justify-between">
                    <div className="font-semibold text-black">{item.name}</div>
                    <div className="flex items-center space-x-1">
                      <div className="text-gray-800">Time</div>
                      {/* Display class time or status (e.g., "Now", "Upcoming") */}
                    </div>
                  </div>
                  <div className="text-gray-700">{item.teacher}</div>
                  <div className="text-gray-900">{item.subject}</div>
                </motion.div>
              ))
            ) : (
              <div className="text-white">No classes today</div>
            )}
          </motion.div>

          {/* News and Updates Section */}
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="flex-[0.65] h-full space-y-2 flex flex-col items-center bg-white px-5 py-10 m-6 mr-10 rounded-2xl"
          >
            <div className="text-xl text-center text-black font-semibold m-2">
              Notifications
            </div>
          </motion.div>
        </div>
      </motion.main>
    </div>
  );
}
