import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";

import "leaflet-defaulticon-compatibility";
// END: Preserve spaces to avoid auto-sorting
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export default function Map() {
  return (
    <div className="p-6 rounded-lg border-1 md:w-1/2">
      <div className="relative z-0 aspect-w-2 aspect-h-1 md:aspect-w-16 md:aspect-h-9">
        <MapContainer
          preferCanvas={true}
          center={[36.689075, 2.869052]}
          zoom={15}
          scrollWheelZoom={true}
          style={{ height: "25rem", width: "25rem" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[36.689075, 2.869052]}>
            <Popup>National School of Nanoscience and Nanotechnology</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
}
