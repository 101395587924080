import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import Sidebar from "./Sidebar";
import { useStore } from "../store";
import { baseURL } from "../constants";

const containerVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.5, type: "spring", stiffness: 100 },
  },
  exit: { opacity: 0, x: 100, transition: { duration: 0.3 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, type: "spring", stiffness: 100 },
  },
};

export default function StudentClass() {
  const { teacherId, type, id } = useParams();
  const user = useStore((state) => state.user);
  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    fetchLectures();
  }, [type, id, teacherId]);

  const fetchLectures = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/lectures/${teacherId}/${type}/${id}`
      );
      setLectures(response.data);
    } catch (error) {
      console.error("Error fetching lectures:", error);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100 text-gray-900">
      <Sidebar isClasses />
      <motion.div
        className="flex-1 p-10"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h1 className="text-4xl font-bold mb-8 text-gray-800">
          {type.charAt(0).toUpperCase() + type.slice(1)} Lectures
        </h1>

        {/* Lectures List */}
        <div className="space-y-6">
          {lectures.map((lecture) => (
            <motion.div
              key={lecture.id}
              variants={itemVariants}
              className="bg-white p-6 rounded-lg shadow-md"
            >
              <h3 className="text-2xl font-semibold mb-2">{lecture.title}</h3>
              <p className="text-gray-600 mb-4">{lecture.content}</p>
              {lecture.hasFile && (
                <a
                  href={`${baseURL}/uploads/${lecture.filePath}`}
                  className="text-blue-500 underline mb-4 inline-block"
                  target="_blank"
                  download
                >
                  Download File
                </a>
              )}
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}
