import { Link, useHistory } from "react-router-dom";
import { useStore } from "../store";

export default function Sidebar({
  isHome = false,
  isData = false,
  isConsultation = false,
  isSection = false,
  isStudents = false,
  isTeachers = false,
  isNews = false,
}) {
  const setIsLoggedIn = useStore((state) => state.setIsLoggedIn);
  const setUser = useStore((state) => state.setUser);

  const history = useHistory();

  const handleLogout = async () => {
    setIsLoggedIn(false);
    setUser(null);

    history.replace("/");
  };

  return (
    <div className="flex flex-col items-center justify-between bg-primary/80 px-5 py-24 mr-5">
      <div className="flex flex-col items-center">
        <Link
          to="/admin/dashboard"
          className={`${
            isHome && "bg-primary"
          } rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          Dashboard
        </Link>

        <Link
          to="/admin/data"
          className={`${
            isData && "bg-primary"
          } rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          Data
        </Link>

        <Link
          to="/admin/consultations"
          className={`${
            isConsultation && "bg-primary"
          } rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          Consultations
        </Link>

        <Link
          to="/admin/sections"
          className={`${
            isSection && "bg-primary"
          } rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          Sections
        </Link>

        <Link
          to="/admin/students"
          className={`${
            isStudents && "bg-primary"
          } rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          Students
        </Link>

        <Link
          to="/admin/teachers"
          className={`${
            isTeachers && "bg-primary"
          } rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          Teachers
        </Link>

        <Link
          to="/admin/news"
          className={`${
            isNews && "bg-primary"
          } rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white`}
        >
          {/* Add an icon here */}
          News
        </Link>

        <button
          className="rounded-2xl p-3 my-3 hover:bg-secondary cursor-pointer text-white"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
}
