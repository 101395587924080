import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

// Replace with your actual image URLs
import nanoscienceImage1 from "./assets/n1.jpeg";
import nanoscienceImage2 from "./assets/n2.jpeg";
import nanotechnologyImage1 from "./assets/n3.jpeg";
import nanotechnologyImage2 from "./assets/n4.jpeg";
import NewHeader from "./Components/newHeader";

function FieldPage() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      once: false,
      duration: 1200, // Adjust animation duration as needed
    });
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-default text-gray-950">
      <NewHeader isField />
      <section className="container mx-auto px-4 py-4 pt-32">
        <div class="text-center mb-4">
          <h1 class="text-4xl font-[Lexend] text-center font-black leading-tighter tracking-tighter mb-4 text-primary">
            {t("Nanoscience and Nanotechnology")}
          </h1>
          <p class="text-xl text-gray-600">
            {t("The Title of Precision and the Gateway to the Future")}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-right">
            <img
              src={nanoscienceImage1}
              alt="Nanoscience"
              className="rounded-md shadow-lg h-96 w-full object-cover"
            />
          </div>
          <div data-aos="fade-left">
            <h2 className="text-3xl font-bold text-secondary mb-4">
              {t("Nanoscience and Nanotechnology in General")}
            </h2>
            <p className="text-lg">
              {t(
                "The field of nanoscience and nanotechnology is incredibly young, with its practical applications emerging only around 1990. This means that the possibilities within nanotechnology are vast and largely unexplored, making research in this field limited but holding immense potential for a new era of advancements across the globe."
              )}
            </p>
          </div>
        </div>
      </section>

      <section className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center md:order-2">
          <div data-aos="fade-right">
            <h2 className="text-3xl font-bold text-secondary mb-4">
              {t("Defining the Field")}
            </h2>
            <p className="text-lg">
              {t(
                "While it's commonly understood that nanotechnology deals with things measured in nanometers, this definition is incomplete. Nanotechnology encompasses a broader scope, extending beyond the nanometer scale to include even smaller and more precise dimensions."
              )}
              <br />
              <br />
              {t(
                "This field touches upon various disciplines. For example, living cells have recently been discovered to possess nano-sized receptors within their cytoplasm. Additionally, nanotechnology allows humans to manipulate and control the properties of molecules, creating new ones with unnatural characteristics capable of achieving seemingly impossible tasks."
              )}
              <br />
              <br />
              {t(
                "One example of this is self-healing metal – a dream turned reality. This metal uses nanotechnology to repair itself without human intervention. Nanotechnology is also used in clothing, creating shirts that repel liquids, a feat unimaginable before the advent of nanotechnology and its ability to modify polyester properties."
              )}
            </p>
          </div>
          <div data-aos="fade-left">
            <img
              src={nanotechnologyImage1}
              alt="Nanotechnology"
              className="rounded-md shadow-lg h-96 w-full object-cover"
            />
          </div>
        </div>
      </section>

      <section className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-right">
            <img
              src={nanoscienceImage2}
              alt="Nanoscience"
              className="rounded-md shadow-lg h-96 w-full object-cover"
            />
          </div>
          <div data-aos="fade-left">
            <h2 className="text-3xl font-bold text-secondary mb-4">
              {t("Applications of Nanotechnology")}
            </h2>
            <p className="text-lg">
              {t(
                "Frankly, due to the boundless nature of nanotechnology, everything imaginable incorporates it in some way or another. From processor technology and medicine to industry, agriculture, electronics, renewable energy, and even nano-scale machines and robots – all of this and more falls under the umbrella of nanotechnology. The term “limitless” truly applies."
              )}
            </p>
          </div>
        </div>
      </section>

      <section className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center md:order-2">
          <div data-aos="fade-right">
            <h2 className="text-3xl font-bold text-secondary mb-4">
              {t("Prospects in Algeria")}
            </h2>
            <p className="text-lg">
              {t("The future of This technologies in Algeria will be amazing")}
            </p>
          </div>
          <div data-aos="fade-left">
            <img
              src={nanotechnologyImage2}
              alt="Nanotechnology"
              className="rounded-md shadow-lg h-96 w-full object-cover"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default FieldPage;
