import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import Sidebar from "./Sidebar";
import { baseURL } from "../constants";
import { FaUserTie, FaChalkboardTeacher, FaUsers } from "react-icons/fa";

export default function AdminTeachers() {
  const [teachers, setTeachers] = useState([]);
  const [sections, setSections] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [teachersRes, sectionsRes, groupsRes] = await Promise.all([
          axios.get(`${baseURL}/teachers`),
          axios.get(`${baseURL}/sections`),
          axios.get(`${baseURL}/groups`),
        ]);
        setTeachers(teachersRes.data);
        setSections(sectionsRes.data);
        setGroups(groupsRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        showNotification("Failed to load data. Please try again.", "error");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const assignTeacher = async (type) => {
    if (!selectedTeacher) return;
    try {
      if (type === "section" && selectedSection) {
        await axios.post(
          `${baseURL}/teachers/${selectedTeacher.id}/sections/${selectedSection}`
        );
        showNotification("Teacher assigned to section successfully!", "success");
      } else if (type === "group" && selectedGroup) {
        await axios.post(
          `${baseURL}/teachers/${selectedTeacher.id}/groups/${selectedGroup}`
        );
        showNotification("Teacher assigned to group successfully!", "success");
      }
      setSelectedTeacher(null);
      setSelectedSection("");
      setSelectedGroup("");
    } catch (error) {
      console.error("Error assigning teacher:", error);
      showNotification("Failed to assign teacher. Please try again.", "error");
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar isTeachers />
      <div className="flex-1 p-10">
        <h1 className="text-4xl font-bold mb-8 text-gray-800">Teacher Assignment Portal</h1>
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {teachers.map((teacher) => (
              <motion.div
                key={teacher.id}
                className={`bg-white rounded-xl p-6 shadow-lg transition-all duration-300 ${
                  selectedTeacher?.id === teacher.id ? 'ring-2 ring-blue-500' : ''
                }`}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setSelectedTeacher(teacher)}
              >
                <div className="flex items-center mb-4">
                  <FaUserTie className="text-3xl text-blue-500 mr-3" />
                  <h2 className="text-2xl font-semibold text-gray-800">{teacher.userName}</h2>
                </div>
                <p className="mb-4 text-gray-600">Specialization: {teacher.specialization}</p>
                <AnimatePresence>
                  {selectedTeacher?.id === teacher.id && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="space-y-4"
                    >
                      <div className="flex items-center space-x-2">
                        <FaChalkboardTeacher className="text-green-500" />
                        <select
                          className="flex-grow bg-gray-100 text-gray-800 rounded-md p-2"
                          value={selectedSection}
                          onChange={(e) => setSelectedSection(e.target.value)}
                        >
                          <option value="">Select Section</option>
                          {sections.map((section) => (
                            <option key={section.id} value={section.id}>
                              {section.name}
                            </option>
                          ))}
                        </select>
                        <motion.button
                          className="bg-gradient-to-r from-green-400 to-blue-500 text-white font-bold py-2 px-4 rounded-full"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => assignTeacher("section")}
                        >
                          Assign
                        </motion.button>
                      </div>
                      <div className="flex items-center space-x-2">
                        <FaUsers className="text-purple-500" />
                        <select
                          className="flex-grow bg-gray-100 text-gray-800 rounded-md p-2"
                          value={selectedGroup}
                          onChange={(e) => setSelectedGroup(e.target.value)}
                        >
                          <option value="">Select Group</option>
                          {groups.map((group) => (
                            <option key={group.id} value={group.id}>
                              {group.name}
                            </option>
                          ))}
                        </select>
                        <motion.button
                          className="bg-gradient-to-r from-purple-400 to-pink-500 text-white font-bold py-2 px-4 rounded-full"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => assignTeacher("group")}
                        >
                          Assign
                        </motion.button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        )}
        <AnimatePresence>
          {notification && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className={`fixed bottom-5 right-5 p-4 rounded-md shadow-lg ${
                notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
              } text-white`}
            >
              {notification.message}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}