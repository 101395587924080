import { useState, useRef, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoMdPlay } from "react-icons/io";
import { useTranslation } from "react-i18next";

export default function ModalVideo({
  thumb,
  thumbWidth,
  thumbHeight,
  thumbAlt,
  video,
  videoWidth,
  videoHeight,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const videoRef = useRef(null);

  // Language state and function
  const { t } = useTranslation();

  return (
    <div>
      {/* Video thumbnail */}
      <div>
        <div
          className="relative flex justify-center mb-8"
          data-aos="zoom-y-out"
          data-aos-delay="450"
        >
          <div className="flex flex-col justify-center rounded-md">
            <img
              src={thumb}
              width={thumbWidth}
              height={thumbHeight}
              alt={thumbAlt}
              className="rounded-md"
            />
          </div>
          <button
            className="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-md font-medium group p-4 shadow-lg"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <IoMdPlay
              size={20}
              className="text-gray-400 group-hover:text-tertiary"
            />
            <span className="ml-3 text-black">
            {t("Watch the introduction video")}
            </span>
          </button>
        </div>
      </div>
      {/* End: Video thumbnail */}

      <Transition
        show={modalOpen}
        as={Fragment}
        afterEnter={() => videoRef.current?.play()}
      >
        <Dialog initialFocus={videoRef} onClose={() => setModalOpen(false)}>
          {/* Modal backdrop */}
          <Transition.Child
            className="fixed inset-0 z-[99999] bg-black bg-opacity-75 transition-opacity"
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            aria-hidden="true"
          />
          {/* End: Modal backdrop */}

          {/* Modal dialog */}
          <Transition.Child
            className="fixed inset-0 z-[99999] overflow-hidden flex items-center justify-center transform px-4 sm:px-6"
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="max-w-6xl mx-auto h-full flex items-center">
              <Dialog.Panel
                className="w-full max-h-full bg-black overflow-hidden"
                style={{ aspectRatio: "9 / 16" }}
              >
                <video
                  ref={videoRef}
                  width={videoWidth}
                  height={videoHeight}
                  loop
                  controls
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Dialog.Panel>
            </div>
          </Transition.Child>
          {/* End: Modal dialog */}
        </Dialog>
      </Transition>
    </div>
  );
}
