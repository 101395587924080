import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import Sidebar from "./Sidebar";
import { useStore } from "../store";
import { baseURL } from "../constants";

const containerVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.5, type: "spring", stiffness: 100 },
  },
  exit: { opacity: 0, x: 100, transition: { duration: 0.3 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, type: "spring", stiffness: 100 },
  },
};

export default function TeacherClass() {
  const { type, id } = useParams();
  const user = useStore((state) => state.user);
  const [lectures, setLectures] = useState([]);
  const [newLecture, setNewLecture] = useState({
    title: "",
    content: "",
    file: null,
  });
  const [editingLecture, setEditingLecture] = useState(null);

  useEffect(() => {
    fetchLectures();
  }, [type, id, user.id]);

  const fetchLectures = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/lectures/${user.id}/${type}/${id}`
      );

      console.log(response)
      setLectures(response.data);
    } catch (error) {
      console.error("Error fetching lectures:", error);
    }
  };

  const handleAddLecture = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", newLecture.title);
    formData.append("content", newLecture.content);
    if (newLecture.file) {
      formData.append("file", newLecture.file);
    }

    try {
      await axios.post(
        `${baseURL}/lectures/${user.id}/${type}/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setNewLecture({ title: "", content: "", file: null });
      fetchLectures();
    } catch (error) {
      console.error("Error adding lecture:", error);
    }
  };

  const handleEditLecture = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", editingLecture.title);
    formData.append("content", editingLecture.content);
    if (editingLecture.file) {
      formData.append("file", editingLecture.file);
    }

    try {
      await axios.put(
        `${baseURL}/lectures/${user.id}/${editingLecture.id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      setEditingLecture(null);
      fetchLectures();
    } catch (error) {
      console.error("Error editing lecture:", error);
    }
  };

  const handleDeleteLecture = async (lectureId) => {
    try {
      await axios.delete(`${baseURL}/lectures/${user.id}/${lectureId}`);
      fetchLectures();
    } catch (error) {
      console.error("Error deleting lecture:", error);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100 text-gray-900">
      <Sidebar isClasses />
      <motion.div
        className="flex-1 p-10"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h1 className="text-4xl font-bold mb-8 text-gray-800">
          {type.charAt(0).toUpperCase() + type.slice(1)} Lectures
        </h1>

        {/* Add New Lecture Form */}
        <form
          onSubmit={handleAddLecture}
          className="mb-8 bg-white p-6 rounded-lg shadow-md"
        >
          <h2 className="text-2xl font-semibold mb-4">Add New Lecture</h2>
          <input
            type="text"
            value={newLecture.title}
            onChange={(e) =>
              setNewLecture({ ...newLecture, title: e.target.value })
            }
            placeholder="Lecture Title"
            className="w-full mb-4 p-2 border rounded"
            required
          />
          <textarea
            value={newLecture.content}
            onChange={(e) =>
              setNewLecture({ ...newLecture, content: e.target.value })
            }
            placeholder="Lecture Content"
            className="w-full mb-4 p-2 border rounded"
            required
          />
          <input
            type="file"
            onChange={(e) =>
              setNewLecture({ ...newLecture, file: e.target.files[0] })
            }
            className="w-full mb-4 p-2 border rounded"
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add Lecture
          </button>
        </form>

        {/* Lectures List */}
        <div className="space-y-6">
          {lectures.map((lecture) => (
            <motion.div
              key={lecture.id}
              variants={itemVariants}
              className="bg-white p-6 rounded-lg shadow-md"
            >
              {editingLecture && editingLecture.id === lecture.id ? (
                <form onSubmit={handleEditLecture}>
                  <input
                    type="text"
                    value={editingLecture.title}
                    onChange={(e) =>
                      setEditingLecture({
                        ...editingLecture,
                        title: e.target.value,
                      })
                    }
                    className="w-full mb-4 p-2 border rounded"
                    required
                  />
                  <textarea
                    value={editingLecture.content}
                    onChange={(e) =>
                      setEditingLecture({
                        ...editingLecture,
                        content: e.target.value,
                      })
                    }
                    className="w-full mb-4 p-2 border rounded"
                    required
                  />
                  <input
                    type="file"
                    onChange={(e) =>
                      setEditingLecture({
                        ...editingLecture,
                        file: e.target.files[0],
                      })
                    }
                    className="w-full mb-4 p-2 border rounded"
                  />
                  <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-2"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setEditingLecture(null)}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                </form>
              ) : (
                <>
                  <h3 className="text-2xl font-semibold mb-2">
                    {lecture.title}
                  </h3>
                  <p className="text-gray-600 mb-4">{lecture.content}</p>
                  {lecture.hasFile && (
                    <a
                      href={`${baseURL}/uploads/${lecture.filePath}`}
                      className="text-blue-500 underline mb-4 inline-block"
                      target="_blank"
                      download
                    >
                      Download File
                    </a>
                  )}
                  <button
                    onClick={() => setEditingLecture(lecture)}
                    className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteLecture(lecture.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </>
              )}
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}
