import React, { useState } from "react";
import { baseURL } from "./constants";
import { useStore } from "./store";
import axios from "axios";
import { useHistory } from "react-router-dom";
import NewHeader from "./Components/newHeader";

const LoginPage = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");

  const setIsLoggedIn = useStore((state) => state.setIsLoggedIn);
  const setUser = useStore((state) => state.setUser);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessage("Signing in...");

    try {
      const response = await axios.post(`${baseURL}/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        // Successful login
        const userData = response.data;
        setIsLoggedIn(true);
        setUser(userData);
        setMessage("Login successful!");
        if (userData.type === "admin") {
          history.replace("/admin/dashboard");
        }
        if (userData.type === "teacher") {
          history.replace("/teacher/dashboard");
        }
        if (userData.type === "student") {
          history.replace("/student/dashboard");
        }
      } else {
        // Handle login error
        setMessage("Invalid credentials");
      }
    } catch (error) {
      console.error(error);
      setMessage("An error occurred during login.");
    }
  };

  return (
    <section className="min-h-screen flex flex-col w-full">
      <NewHeader />
      <div className="w-full mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12">
          {/* Page header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h1 className="text-4xl font-[Lexend] text-center font-black leading-tighter tracking-tighter mb-4 text-primary">Welcome back</h1>
          </div>

          <div className="w-1/2 mx-auto bg-white p-4 rounded-lg shadow-lg shadow-[#ccc]">
            <form>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block text-gray-800 text-sm font-medium mb-1"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-input w-full text-gray-800"
                    placeholder="Enter your email address"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <div className="flex justify-between">
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <div className="text-sm font-medium text-primary hover:underline">
                      Forget Password
                    </div>
                  </div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-input w-full text-gray-800"
                    placeholder="Enter your password"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mt-6">
                <div className="w-full px-3">
                  <button
                    onClick={handleSubmit}
                    className="btn text-white bg-tertiary hover:bg-tertiary/80 w-full"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </form>
            <p className="text-center text-black">{message}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
